import React from "react";
import styled from "styled-components";
import LineChart from "./LineChart";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  min-width: 806px;
  height: 545px;
  flex: 1;
  margin-right: 20px;
  background-color: ${({ theme }) => theme.whiteColor};

  &:last-child {
    margin: 0;
  }
`;
const Title = styled.div`
  height: 50px;
  padding: 20px 20px 12px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrayBorder};
`;
const Text = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 16)}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.defaultFontColor};
`;
const Contents = styled.div`
  height: calc(100% - 50px);
  padding: 20px;
`;
const Table = styled.div`
  margin-top: 10px;
`;
const TableRow = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ bgColor, theme }) =>
    bgColor ? theme.lightGrayBorder : "inherit"};
`;
const TableCell = styled.div`
  width: ${({ width }) => width}%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrayBorder};

  &:nth-child(1) {
    padding-left: 20px;
  }
`;
const TableBody = styled.div`
  overflow: scroll;
  max-height: 400px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
const EllipsisText = styled.div`
  width: 90%;
  padding-top: 2px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const LargeTableBox = ({ CHART, TABLE, title, subTitle, data = [] }) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Title>
        <Text>{title}</Text>
        {subTitle && <Text>{subTitle}</Text>}
      </Title>
      <Contents>
        {CHART && <LineChart data={data} />}

        {TABLE && (
          <Table>
            <TableRow bgColor>
              <TableCell width={35}>프로젝트명</TableCell>
              <TableCell width={13}>SCAR 발행</TableCell>
              <TableCell width={13}>최종완료</TableCell>
              <TableCell width={13}>진행중</TableCell>
              <TableCell width={10}>지연</TableCell>
              <TableCell width={13}>평균 조치일</TableCell>
            </TableRow>
            <TableBody>
              {data.map((list, index) => (
                <TableRow
                  key={`${index}-list`}                  
                >
                  <TableCell width={35} 
                    style={{cursor:'pointer'}}
                    onClick={() => {
                      navigate("/project/projectList/detail");
                      localStorage.setItem("prjId", list?.prj_id);
                    }}>
                    <EllipsisText>
                      {list.prjTitle.toLocaleString()}
                    </EllipsisText>
                  </TableCell>
                  <TableCell width={13}>
                    <EllipsisText>
                      {list.scarCount.toLocaleString()} 건
                    </EllipsisText>
                  </TableCell>
                  <TableCell width={13}>
                    <EllipsisText>
                      {list.completeCount.toLocaleString()} 건
                    </EllipsisText>
                  </TableCell>
                  <TableCell width={13}>
                    <EllipsisText>
                      {list.inProgressCount.toLocaleString()} 건
                    </EllipsisText>
                  </TableCell>
                  <TableCell width={10}>
                    <EllipsisText>
                      {list.delayCount.toLocaleString()} 건
                    </EllipsisText>
                  </TableCell>
                  <TableCell width={13}>
                    <EllipsisText>{list.avgDay} 일</EllipsisText>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Contents>
    </Wrapper>
  );
};

export default React.memo(LargeTableBox);
