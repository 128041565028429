import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "@apollo/client";
import {
  SEE_BY_CHECK_LIST_COUNT,
  SEE_BY_PROJECT_SCAR_COUNT,
  SEE_BY_SCAR_PROCESS_COUNT,
  SEE_BY_SCAR_STATUS_COUNT,
  SEE_BY_YEAR_PROJECT_COUNT,
  SEE_BY_YEAR_SCAR_COUNT,
  SEE_CS_COUNT,
  SEE_GROUP_AUTH_CODE,
  SEE_PROJECT_COMPLETE_RATIO,
  SEE_TOTAL_SCAR_COUNT,
  SEE_USER_STATISTIC,
} from "../../../graphql/Home/Stats/query";
import { AuthContext } from "../../../context/AuthProvider";
import StatPresenterRenewal from "./StatPresenterRenewal";

const StatContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [refreshTime, setRefreshTime] = useState("");
  const [scarCount, setScarCount] = useState([]);
  const [scarProgressCount, setScarProgressCount] = useState([]);
  const [checkListCount, setCheckListCount] = useState([]);
  const [userStats, setUserStats] = useState([]);
  const [userTotalCount, setUserTotalCount] = useState(0);
  const [scarYearCount, setScarYearCount] = useState([]);
  const [projectStats, setProjectStats] = useState([]);
  const today = new Date().getTime();
  const endDate = new Date(2024, 2, 1).getTime();
  const dDay = useRef(Math.ceil((endDate - today) / (24 * 60 * 60 * 1000)));

  const {
    data: scarCountData,
    loading: scarCountLoading,
    refetch: scarCountRefetch,
  } = useQuery(SEE_BY_SCAR_STATUS_COUNT);
  const {
    data: scarProgressCountData,
    loading: scarProgressCountLoading,
    refetch: scarProgressCountRefetch,
  } = useQuery(SEE_BY_SCAR_PROCESS_COUNT);
  const {
    data: checkListCountData,
    loading: checkListCountLoading,
    refetch: checkListCountRefetch,
  } = useQuery(SEE_BY_CHECK_LIST_COUNT);
  const {
    data: scarTotalData,
    loading: scarTotalLoading,
    refetch: scarTotalDataRefetch,
  } = useQuery(SEE_TOTAL_SCAR_COUNT);
  const {
    data: userData,
    loading: userLoading,
    refetch: userRefetch,
  } = useQuery(SEE_USER_STATISTIC);
  const {
    data: scarYearData,
    loading: scarYearLoading,
    refetch: scarYearRefetch,
  } = useQuery(SEE_BY_YEAR_SCAR_COUNT);
  const {
    data: projectScarData,
    loading: projectScarLoading,
    refetch: projectScarRefetch,
  } = useQuery(SEE_BY_PROJECT_SCAR_COUNT);
  const {
    data: yearProjectData,
    loading: yearProjectLoading,
    refetch: yearProjectRefetch,
  } = useQuery(SEE_BY_YEAR_PROJECT_COUNT);
  const {
    data: projectCompleteRatioData,
    loading: projectCompleteRatioLoading,
    refetch: projectCompleteRatioRefetch,
  } = useQuery(SEE_PROJECT_COMPLETE_RATIO);
  const {
    data: csData,
    loading: csLoading,
    refetch: csRefetch,
  } = useQuery(SEE_CS_COUNT);
  const {
    data: authCodeData,
    loading: authCodeLoading,
    refetch: authCodeRefetch,
  } = useQuery(SEE_GROUP_AUTH_CODE);

  const handleAllRefresh = useCallback(async () => {
    const now = new Date();
    const min =
      now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
    setRefreshTime(`${now.getHours()}:${min}`);

    await scarCountRefetch();
    await scarProgressCountRefetch();
    await checkListCountRefetch();
    await scarTotalDataRefetch();
    await userRefetch();
    await scarYearRefetch();
    await projectScarRefetch();
    await yearProjectRefetch();
    await projectCompleteRatioRefetch();
    await csRefetch();
    await authCodeRefetch();
  }, [
    scarCountRefetch,
    scarProgressCountRefetch,
    checkListCountRefetch,
    scarTotalDataRefetch,
    userRefetch,
    scarYearRefetch,
    projectScarRefetch,
    yearProjectRefetch,
    projectCompleteRatioRefetch,
    csRefetch,
    authCodeRefetch,
  ]);

  useEffect(() => {
    if (!scarCountLoading && scarCountData) {
      setScarCount([
        {
          title: "조치필요",
          count:
            scarCountData.seeByScarStatusCount.byStatusCount.actionRequireCount,
        },
        {
          title: "확인필요",
          count:
            scarCountData.seeByScarStatusCount.byStatusCount
              .confirmRequireCount,
        },
        {
          title: "최종완료",
          count: scarCountData.seeByScarStatusCount.byStatusCount.finalCount,
        },
        {
          title: "진행중",
          count:
            scarCountData.seeByScarStatusCount.byStatusCount.inProgressCount,
        },
        {
          title: "반려",
          count: scarCountData.seeByScarStatusCount.byStatusCount.rejectCount,
        },
      ]);
    }
  }, [scarCountLoading, scarCountData]);

  useEffect(() => {
    if (
      userData?.seeUserStatistic &&
      !userData?.seeUserStatistic?.tokenExpired
    ) {
      userLogout();
    }
    if (userData?.seeUserStatistic?.result) {
      setUserStats([
        { name: "PM본사", value: userData.seeUserStatistic.head },
        { name: "PM현장", value: userData.seeUserStatistic.local },
        { name: "시공사", value: userData.seeUserStatistic.builder },
        { name: "발주처", value: userData.seeUserStatistic.owner },
      ]);
      setUserTotalCount(userData.seeUserStatistic.totalCount);
    }
  }, [userData]);

  useEffect(() => {
    if (!scarYearLoading && yearProjectData) {
      let tmpArray = [
        ...yearProjectData.seeByYearProjectCount.byYearProjectCountInfo,
      ];
      tmpArray.sort((a, b) => b.year - a.year);

      setProjectStats(
        tmpArray.map((list) => ({
          title: list.year,
          count: list.projectCount,
        })),
      );
    }
  }, [scarYearLoading, yearProjectData]);

  useEffect(() => {
    if (!scarProgressCountLoading && scarProgressCountData) {
      setScarProgressCount([
        {
          name: "최종완료",
          value:
            scarProgressCountData.seeByScarProcessCount.byProcessScarCount
              .complete,
        },
        {
          name: "진행중",
          value:
            scarProgressCountData.seeByScarProcessCount.byProcessScarCount
              .inProgress,
        },
        {
          name: "지연",
          value:
            scarProgressCountData.seeByScarProcessCount.byProcessScarCount
              .delay,
        },
      ]);
    }
  }, [scarProgressCountLoading, scarProgressCountData]);

  useEffect(() => {
    if (!checkListCountLoading && checkListCountData) {
      setCheckListCount([
        {
          name: "진행중",
          value:
            checkListCountData.seeByCheckListCount.byCheckListCount.inProgress,
        },
        {
          name: "지연",
          value: checkListCountData.seeByCheckListCount.byCheckListCount.delay,
        },
      ]);
    }
  }, [checkListCountLoading, checkListCountData]);

  useEffect(() => {
    if (!scarYearLoading && scarYearData) {
      setScarYearCount(
        scarYearData.seeByYearScarCount.byYearScarCount.map((data) => ({
          name: data.year,
          value: data.scarCount,
        })),
      );
    }
  }, [scarYearLoading, scarYearData]);

  return (
    <StatPresenterRenewal
      dDay={dDay}
      refreshTime={refreshTime}
      handleAllRefresh={handleAllRefresh}
      // 현장 SCAR 발행
      allScarTotalCount={scarTotalData?.seeTotalScarCount?.totalScarCount}
      scarTotalLoading={scarTotalLoading}
      // 전체 SCAR 발행 건수
      scarCount={scarCount}
      scarTotalCount={
        scarCountData?.seeByScarStatusCount?.byStatusCount?.totalCount
      }
      checkListInProgressCount={
        checkListCountData?.seeByCheckListCount?.byCheckListCount
          ?.inProgressCount
      }
      checkListCount={checkListCount}
      scarCountLoading={scarCountLoading}
      checkListCountLoading={checkListCountLoading}
      // 사용자 통계
      userStats={userStats}
      userTotalCount={userTotalCount}
      userLoading={userLoading}
      // SCAR 완료율
      scarProgressCount={scarProgressCount}
      scarProgressTotalCount={
        scarProgressCountData?.seeByScarProcessCount?.byProcessScarCount
          ?.completeCount
      }
      scarProgressCountLoading={scarProgressCountLoading}
      // SCAR 발행 추이
      scarYearCount={scarYearCount}
      scarYearLoading={scarYearLoading}
      // 프로젝트별 SCAR 발생 건수
      projectScarData={projectScarData?.seeByProjectScarCount?.projectScarInfo}
      projectScarLoading={projectScarLoading}
      // 프로젝트 생성 통계
      projectStats={projectStats}
      yearProjectLoading={yearProjectLoading}
      // 프로젝트 총 완료율
      projectCompleteRatioData={
        projectCompleteRatioData?.seeProjectCompleteRatio?.byProjectStatusRatio
      }
      projectCompleteRatioLoading={projectCompleteRatioLoading}
      // CS업무
      csData={csData?.seeCsCount?.csCountInfo}
      csLoading={csLoading}
      // 가입 코드
      authCodeData={authCodeData?.seeGroupAuthCode?.codeList}
      authCodeLoading={authCodeLoading}
    />
  );
};

export default StatContainer;
